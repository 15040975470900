import React, {useEffect} from 'react'
import {navigate} from 'gatsby'
import LayoutContainer from '../containers/layout'
import Container from '../components/container'
import Article from '../components/article'
import SEO from '../components/seo'
import ContentSection from '../components/content-section'
import BlockContent from '../components/block-content'

export const query = graphql`
  query BLMPageQuery {
    page: sanityBlmPage(_id: {regex: "/(drafts.|)blmPage/"}) {
      title
      headline
      _rawBody
      seoFields {
        metaTitle
        metaDescription
        metaImage {
          asset {
            url
          }
        }
      }      
    }
  }
`

const BLM = (props) => {
  const {data, errors} = props
  const page = props.data.page
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <LayoutContainer>
      <SEO themeColour="black" title={page.title} />
      <Container>
        <Article>
          {page.headline && (
            <h1 className="type-blm-headline">{page.headline}</h1>
          )}
          <ContentSection>
            <BlockContent blocks={page._rawBody} />
          </ContentSection>
          </Article>
      </Container>
    </LayoutContainer>
  )
}

export default BLM
